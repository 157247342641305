.highlighted-issue {
    background-color: $orange;
    padding: $spacing-xlarge 0;
    
    &__container {
        @include container;
        max-width: $max-width-l;
    }

    &__heading {
        margin: 0 0 23px;
        @include font-size(24px, 32px);
        font-weight: 400;

        @include breakpoint(small) {
            margin: 0 0 $spacing-medium;
            @include font-size(32px, 40px);
        }
    }

    &__link {
        @include underlined-link;
        border-bottom-color: $dark-blue;

        &:hover {
            border-bottom-color: transparent;
        }
    }
}