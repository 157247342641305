@import "../../scss/shared-components-styles/rich-text-common.scss";
@import "../../scss/shared-components-styles/link-common.scss";

.poor-text {
    @include font-size(20px, 28px);

    a.link-type_external {
        @include external-link;
    }

    @include breakpoint(medium) {
        @include font-size(32px, 42px);
    }

    *:first-child {
        margin-top: 0;
    }
    p {
        margin: 0;
        font-size: inherit;
        line-height: inherit;
    }

    ul{
        @include bullet-list;
    }

    &_in-page-intro{
        @include font-size(24px, 32px);
        color: $primary-color;
        font-weight: 500;
    }

    &_in-page-hero{
        font-size: 16px;
        line-height: 24px;

        @include breakpoint(small) {
            font-size: 18px;
            line-height: 26px;
        }
        &.on-editing{
          background-color: $white;
        }
    }

    &_in_full-width-banner-block{
        &.on-editing{
            background-color: $white;
        }
        transition: all 0.5 ease-in-out;
        @include breakpoint(small) {
            font-size: 2vw;
            line-height: 20px;
        }
        @include breakpoint(medium) {
            @include font-size(18px, 26px);
        }
    }

    &_in-footnote {
        &, h1, h2, h3, h4 {
            margin-bottom: 0;
            @include font-size(18px, 26px);
        }
        
        a {
            @include underlined-link;
            font-weight: 500;
        }
    }

    &_in-footnote-active {
        a {
            border-bottom-color: $white;

            &:hover,
            &:focus-visible {
                border-bottom-color: transparent;
            }
        }
    }

    &_in-highlighted-issue {
        @include font-size(16px, 24px);
        @include breakpoint(small) {
            @include font-size(18px, 26px);
        }

        a {
            @include underlined-link;
            border-bottom-color: $dark-blue;

            &:hover {
                border-bottom-color: transparent;
            }
        }
    }
}
