$panel-border-width: 17px;

@mixin tab-list {
    @extend .container_narrow;
    display: flex;
    list-style: none;
    margin-top: $spacing-xlarge;

    @include breakpoint(small){
        justify-content: flex-start;
        width: auto;
    }

    @include breakpoint(small, "max"){
        justify-content: space-between;
        width: 420px;
        margin-left: 0;
        margin-right: 0;
    }
}

@mixin tab-item {
    position: relative;

    &:not(:last-child) {
        @include breakpoint(small){
            margin-right: 80px;
        }
    }
}

@mixin tab-link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    @include font-size(18px, 26px);
    font-family: $font-family;
    font-weight: 500;
    color: $dark-blue;
    text-decoration: none;
    padding-bottom: 30px;
    border-width: 0;
    border-bottom: 17px solid transparent;
    outline: none;
    background: none;
    cursor: pointer;

    &_active,
    &:focus-visible,
    &:hover {
        border-bottom-color: $dark-blue;
    }

    @include breakpoint(small) {
        @include font-size(24px, 32px);
        flex-direction: row;
    }
}

@mixin tab-icon {
    margin-bottom: $spacing-small;

    @include breakpoint(small) {
        margin-right: $spacing-medium;
        margin-bottom: 0;
    }
}