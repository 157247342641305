.page-heading {
    @extend .container_narrow;
    @include font-size(40px, 48px);
    color: $secondary-color;
    font-family: $font-family;
    font-weight: 500;
    margin-bottom: 14px;
    letter-spacing: 0.1px;

    @include breakpoint(small) {
        margin-bottom: 28px;
        @include font-size(48px, 56px);
    }
}