.language-selector {
    $self: &;
    padding: 0;

    &:hover {
        @include breakpoint(large) {
            #{$self}__list {
                display: block;
            }
        }
    }

    &__opener {
        display: inline-flex;
        align-items: center;
        height: 100%;
        background: none;
		padding: 0 1px;
		font-family: $font-family;
        @include font-size(16px, 20px);
		color: $white;
		border: none;
		cursor: pointer;

        @include breakpoint(large) {
            padding: 27px;
            @include font-size(18px, 26px);
            outline: none;
        }

        &:hover,
        &:focus-visible {
            @include breakpoint(large) {
                background-color: $blue;
                color: inherit;
            }
        }

        &:active {
            background-color: $blue;
        }

        &-icon {
            @include breakpoint(large) {
                display: none;
            } 
        }

        &-text {
            @include breakpoint(large, "max") {
                margin-left: 8px;
            } 
        }
    }

    &__list {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        list-style: none;
        z-index: 1;

        @include breakpoint(large) {
            left: -2px;
            right: -2px;
            padding-top: 8px;
        }

        &_active {
            display: block;
        }
    }

    &__item {
        border-color: $blue;
        border-width: 2px 0 0;
        border-style: solid;
        @include font-size(16px, 24px);
        text-align: center;
        color: $white;
        cursor: default;

        &:last-child {
            border-bottom-width: 2px;
        }

        @include breakpoint(large) {
            border-width: 2px 2px 0 2px;
        }

        &_current #{$self}__link {
            background-color: $blue;
        }
    }

    &__link {
        display: block;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        text-decoration: none;
        padding: 8px 16px;
        background-color: $dark-blue;

        &:hover,
        &:focus-visible {
            background-color: $blue;
        }

        @include breakpoint(large) {
            padding: 7px 9px;
        }
    }
}