$circle-size: 147px;
$circle-size-mobile: 127px;
$max-icon-size: 70%;

.circle-icon {
	$self: &;
	position: relative;
	display: inline-block;
	width: $circle-size-mobile;
	flex: 0 0 $circle-size-mobile;
	height: $circle-size-mobile;
	border-radius: 50%;
	background-color: $white;
	color: $dark-blue;

	@include breakpoint(small) {
		width: $circle-size;
		flex: 0 0 $circle-size;
		height: $circle-size;
	}

	&_active {
		background-color: $dark-blue;
		color: $white;
	}

	&__image {
		@include center-align(absolute);
		max-width: $max-icon-size;
		max-width: $max-icon-size;
	}
}