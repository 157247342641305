@import '../../scss/shared-components-styles/footnote.scss';

.footnotes {
    @include container;
    margin-top: $block-margin;
    margin-bottom: $block-margin;

    @include breakpoint(small) {
        margin-top: calc($block-margin/2);
        margin-bottom: calc($block-margin/2);
    }

    &__container {
        background-color: $white;
        padding: $spacing-medium 20px 20px;

        @include breakpoint(small) {
            padding-bottom: 28px;
        }
    }

    &__heading {
        @include font-size(24px, 32px);
        padding-bottom: 12px;
        border-bottom: 2px solid $dark-blue;
        margin: 0 0 $spacing-medium;
    }

    &__list {
        list-style: none;
        counter-reset: footnotes-item-counter;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: $spacing-medium;
        }
    }
}

.footnote {
    $self: &;
    display: inline-flex;
    padding: 3px 6px 5px;

    &_active {
        background-color: $medium-blue;
    }

    &__index {
        @include circle-number;
        margin: 2px 5px 0 0;

        @include breakpoint(small) {
            margin-right: 10px;
        }
    }

    &__key {
        display: none;

        .on-page-editing &,
        .on-editing & {
            @include edit-key-label;
            align-self: flex-start;
            flex: 0 0 auto;
            margin: 4px 6px 0 0;
        }
    }
}