.fact-block {
    clear: both;
    @include container;
    margin-bottom: 48px;

    &__content {
        max-width: $max-content-width;
        margin: 0 auto;
        border: 4px solid $medium-blue;
        padding: $block-margin calc($block-margin/2);
        background: $white;

        @include breakpoint(small) {
            padding: $block-margin;
        }
    }
}
