.background-video {
    $self: &;
    @include full-size;
    overflow: hidden;

    &__video {
        @include center-align(absolute);
        width: auto;
        height: auto;
        max-height: none;
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        z-index: 3;
    }
    
    &__content {
        position: relative;
        min-height: 100%;
        @include container;

        @include breakpoint("extra-small-max", "max") {
            padding-left: 0;
            padding-right: 0;
        }

        @include breakpoint(small) {
            padding-top: $spacing-xmedium;
            padding-bottom: $spacing-xmedium;
        }
    }

    &__play-btn {
        #{$self} & {
            position: absolute;
            right: 16px;
            bottom: 16px;
            z-index: 5;
    
            @include breakpoint(small) {
                top: 32px;
                right: 32px;
                bottom: auto;
            }
        }
    }
}