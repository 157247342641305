.qa-group-block {
  	@extend .container_narrow;
	margin-bottom: $spacing-xxxlarge;

	.content-area > *:last-child & {
		margin-bottom: 0;
	}

	&__heading {
		margin: 0 0 $spacing-xmedium;

		@include breakpoint(small){
			margin: 0 0 33px;
		}
	}

	&__list {
		list-style: none;
	}

	&__item {
		position: relative;
		border-top: 2px solid $medium-blue;
	}

	&__link {
		display: block;
		padding: $spacing-medium $spacing-xxlarge $spacing-medium $spacing-medium;
		color: $dark-blue;
		text-decoration: none;
		@include font-size(16px, 24px);
		font-weight: 500;
		word-break: break-word;
		outline: none;

		&:hover,
		&:focus-visible {
			background-color: $light-blue;
		}

		@include breakpoint(small){
			@include font-size(18px, 26px);
		}
	}

	&__arrow {
		position: absolute;
		top: 28px;
		right: $spacing-medium;
		transform: translateY(-50%);

		@include breakpoint(small){
			@include font-size(18px, 26px);
		}
	}
}