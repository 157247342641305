@mixin external-link {
	position: relative;

	&:after {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-left: 5px;
		width: 13px;
		height: 13px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuNjU2ODUgMC4zNDMxNDZWMi40NjQ0N0g5LjQzNTAzTDAuOTQ5NzQ3IDEwLjk0OTdMMi4zNjM5NiAxMi4zNjRMMTAuODQ5MiAzLjg3ODY4VjExLjY1NjlIMTIuOTcwNlYwLjM0MzE0NkgxLjY1Njg1WiIgZmlsbD0iIzA0MkI2MCIvPgo8L3N2Zz4K');
	}
}

@mixin external-link-light-theme {
	@include external-link;

	&:after {
		background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xLjY1Njg1IDAuMzQzMTQ2VjIuNDY0NDdIOS40MzUwM0wwLjk0OTc0NyAxMC45NDk3TDIuMzYzOTYgMTIuMzY0TDEwLjg0OTIgMy44Nzg2OFYxMS42NTY5SDEyLjk3MDZWMC4zNDMxNDZIMS42NTY4NVoiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==');
	}
}