a {
	text-decoration: underline;
	&:hover,
	&:focus {
		text-decoration: none;
	}
}

p {
	font-size: inherit;
	line-height: inherit;
	margin: 0 0 calc(#{$block-margin}/ 2);
}

h2,
h3,
h4,
h5,
dl dt {
	color: $dark-blue;
	font-family: $font-family;
	margin: $block-margin 0 calc(#{$block-margin}/ 2);
	font-weight: 500;
}

h2 {
	@include font-size(32px, 40px);
}
h3, dl dt {
	@include font-size(24px, 32px);
}
h4 {
	@include font-size(20px, 28px);
}
h5 {
	@include font-size(18px, 26px);
}

img {
	max-width: 100%;
	display: block;
}

strong,
b {
	font-weight: bold;
}

em,
i {
	font-style: italic;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

dl {
	dd {
		margin-left: 0;
	}
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	margin: $spacing-large 0;
}

td,
th {
	vertical-align: top;
	border: 1px solid $black;
	padding: $spacing-medium;
}

mark {
	background-color: $selection-background;
}
