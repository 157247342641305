.page {
	$self: &;
	&__root {
		width: 100%;
		display: table;
		height: 100vh;
		width: 100%;
		table-layout: fixed;

		.header-menu-is-opened &{
			overflow: hidden ;
			display: block;
		}

		&.on-page-editing {
			height: auto;
		}

		&.front-page{
			@include breakpoint("extra-small-max", "max") {
				display: flex;
				flex-direction: column;
			}
		}
	}

	&__highlighted-issue {
		@include breakpoint("extra-small-max", "max") {
			order: 2;
		}
	}

	&__hero{
		@include breakpoint("extra-small-max", "max") {
			order: 3;
		}
	}

	&__header {
		width: 100%;
		top: 0;
		z-index: 10;
		color: $dark-blue;

		#{$self}__root.front-page &{
			@include breakpoint("extra-small-max", "max") {
				order: 1;
			}
		}
	}

	&__breadcrumbs {
		@media print {
			display: none;
		}
	}

	&__content{
		margin: $spacing-xlarge 0 0;

		#{$self}__root.front-page &,
		#{$self}__breadcrumbs + &{
			margin-top: 0;
		}
		#{$self}__root.page-not-found &{
			margin-top: 0;
			position: relative;
			display: table-row;
			height: 100%;
		}
		#{$self}__root.front-page &{
			@include breakpoint("extra-small-max", "max") {
				order: 3;
			}
		}
	}
	&__footer{
		display: table-row;
		height: 1px;
		position: relative;
		z-index: 10;

		@media print {    
			display: none;
		}

		#{$self}__root.front-page &{
			@include breakpoint("extra-small-max", "max") {
				order: 4;
			}
		}
	}
}