.image-with-text-block {
    $self: &;
    @include container;
    display: flex;
    flex-direction: column;
    margin-bottom: $block-margin;
    max-width: calc($max-width + 8px);

    @include breakpoint(medium) {
        flex-wrap: nowrap;
        justify-content: space-between;

        &_image-position_left {
            flex-direction: row;
        }

        &_image-position_right {
            flex-direction: row-reverse;
        }
    }

    &__image{
        padding: 0;
        max-width: 100%;
        width: 100%;

        @include breakpoint(medium) {
            margin: 0;
        }
    }

    &__text-container,
    &__image-container {
        @include breakpoint(medium) {
            width: calc(50% - 11px);
        }
    }

    &__text-container{
        align-self: flex-start;

        @include breakpoint(medium) {
            #{$self}_image-position_left &{
                margin-left: auto;
            }
            #{$self}_image-position_right &{
                margin-right: auto;
            }
        }
    }

    &__image-container {
        @include breakpoint(medium) {
            #{$self}_image-v-alignment_top & {
                align-self: flex-start;
            }
            #{$self}_image-v-alignment_center & {
                align-self: center;
            }
            #{$self}_image-v-alignment_bottom & {
                align-self: flex-end;
            }

            #{$self}_image-position_left &{
                margin-right: auto;
            }
            #{$self}_image-position_right &{
                margin-left: auto;
            }
        }
    }
}
