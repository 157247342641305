.image-block {
  @include container;
  margin-bottom: $block-margin;

  &__container{
    padding: 0;
    margin: 0 auto;
    max-width: $max-width-m;
  }

  &__text-container {
    display: block;
    font-family: $font-family;
    @include  font-size(16px, 24px);
    margin-top: $spacing-medium;

    @include breakpoint(small) {
      margin-top: calc($spacing-medium/2);
    }
  }

  &__photographer{
    font-weight: 500;
    margin-top: 3px;

    @include breakpoint(small) {
      font-weight: 400;
      margin-top: 0;
    }

    &-label{
      @include breakpoint(small) {
        text-transform: uppercase;
      }
      &:after{
        content: ": ";
      }
    }
  }
}
