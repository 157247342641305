@font-face {
    font-family: "Radikal";
    font-weight: 300;
    src: url("../assets/fonts/radikal/32A7AE_3_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_3_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: 300;
    font-style: italic;
    src: url("../assets/fonts/radikal/32A7AE_0_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_0_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: normal;
    src: url("../assets/fonts/radikal/32A7AE_6_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_6_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-style: italic;
    src: url("../assets/fonts/radikal/32A7AE_7_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_7_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: 500;
    src: url("../assets/fonts/radikal/32A7AE_4_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_4_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: 500;
    font-style: italic;
    src: url("../assets/fonts/radikal/32A7AE_5_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_5_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: 700;
    src: url("../assets/fonts/radikal/32A7AE_1_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_1_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: "Radikal";
    font-weight: 700;
    font-style: italic;
    src: url("../assets/fonts/radikal/32A7AE_2_0.woff2") format("woff2"),
         url("../assets/fonts/radikal/32A7AE_2_0.woff") format("woff");
    font-display: swap;
}

@font-face {
    font-family: 'Romain';
    src: url('../assets/fonts/romain/RomainHeadline-Regular-WebS.woff2?v=1') format('woff2'),
         url('../assets/fonts/romain/RomainHeadline-Regular-WebS.woff?v=1') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}