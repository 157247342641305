.header {
	display: flex;
	color: $white;
	background-color: $primary-color;
	min-height: $header-height-mobile;

	@include breakpoint(small) {
		min-height: $header-height-tablet;
	}

	@include breakpoint(large) {
		min-height: $header-height;
	}

  	&__container {
	  	position: relative;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: $spacing-medium;

		@include container;

		@include breakpoint(large) {
			align-items: center;
			padding-top: 0;
			padding-bottom: 0;
			flex-wrap: nowrap;
		}
	} 
	
	&__main-menu {
		position: unset!important;
		width: 50%;
		order: 4;
		margin-top: 27px;
		z-index: 20;
		margin-left: auto;

		@include breakpoint(large) {
			position: static!important;
			height: 100%;
			width: auto;
			order: 2;
			margin: 0 0 0 30px;
		}
	}

	&__language-selector {
		width: 50%;
		order: 3;
		margin-top: 27px;

		@include breakpoint(large) {
			position: relative;
			bottom: 0;
			margin-top: 0;
			height: 100%;
			width: auto;
			border-right: 2px solid $blue;
			border-top: none;
			margin-right: 30px;
		}
	}

	&__logo {
		&-holder {
			@include breakpoint(large, "max") {
				width: 50%;
			}

			&-main {
				order: 1;
			}
	
			&-secondary {
				order: 2;
				margin-left: auto;
	
				@include breakpoint(large) {
					order: 4;
				}
			}
		}
		&-main {
			min-width: 162px;
		} 
		&-secondary {
			margin-left: auto;
		}
	}
}
