.breadcrumbs {
    @include container;

    @media print {
        display: none;
    }

    &__items {
        list-style: none;
        margin: $spacing-medium 0 $spacing-xlarge;
        padding: 0;
    }

    &__item {
        display: inline;
        margin: 0 0 5px;

        @include breakpoint(small) {
            @include font-size(18px, 24px);
        }

        &:last-child {
            pointer-events: none;
        }
    }

    &__separator {
        margin: 0 12px;
        font-size: 20px;
        color: $dark-blue;
    }

    &__link {
        @include font-size(18px, 24px);
        @include underlined-link;
        color: $dark-blue;
        font-weight: 500;

        &_current {
            @include font-size(16px, 24px);
            border-bottom: none;

            @include breakpoint(small) {
                @include font-size(18px, 24px);
            }
        }
    }
}
