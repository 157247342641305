html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mouse-user * {
  outline: none;
}

html,
body {
	font-size: 100%;
	margin: 0;
	overflow: visible !important;
	background: $website-bg-color;
	height: 100%;
	@if $enable-font-smoothing == true {
		font-smooth: always;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
	}
}
html{
	overflow: hidden;
	-webkit-tap-highlight-color: transparent;
	line-height: 1em;
	&[dir="rtl"] { direction: rtl; }
}

body{
	-webkit-font-smoothing: antialiased;
	overflow-y: scroll !important;
 	-moz-osx-font-smoothing: grayscale;
	min-width: $min-width;
	font-family: $font-family;
	font-size: $font-size-em;
	font-weight: $font-weight-normal;
	line-height: $line-height;
	-webkit-overflow-scrolling: touch;
    color: $dark-blue;
	&.header-menu-is-opened{
		overflow: hidden !important;
		&:before {
			content: "";
			@include full-size;
			position: fixed;
			background: $white;
			z-index: 12;
			opacity: 0.5;
		}
	}
}

@page {
	margin-bottom: $spacing-xlarge;
}

.mouse-user *:focus{outline: none;}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.container{
	@include container;

  &_narrow{
    @extend .container;
	max-width: 100%;

	@include breakpoint(small){
		max-width: calc(#{$max-content-width} + #{$spacing-xxlarge} * 2);
	}
  }
}