@import "../../scss/shared-components-styles/image-mixins.scss";

.image {
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  overflow: hidden;

  &_fluid {
    @include fluid-image();
  }

  &__img {
    display: block;
    width: 100%;
  }

  // Example of the possible image sizes

  &_size {
    &_image-with-text-block{
      @include fixed-proportion-image(640, 360);
    }
    &_image-block{
      @include fixed-proportion-image(990, 556);
    }
    &_full-width-banner-block{
      @include fixed-proportion-image(343, 412);

      @include breakpoint(small) {
        @include fixed-proportion-image(1320, 450);
      }
    }

    &_cta-image-block{
      @include fixed-proportion-image(342, 192);
      @include breakpoint(small) {
        @include fixed-proportion-image(655, 366);
      }
    }
    &_cta-image-block-full-width{
      @include fixed-proportion-image(342, 192);
      @include breakpoint(small) {
        @include fixed-proportion-image(1320, 366);
      }
    }
    
    &_header {
      @include fixed-proportion-image(990, 556);
      
      &-main-logo {
        @include original-proportions-image(162, 32);
      }
      
      &-secondary-logo {
        @include original-proportions-image(75, 75);

        @include breakpoint(small) {
          @include original-proportions-image(82, 82);
        }
      }
    }

    &_footer {
      &-main-logo {
        @include original-proportions-image(162, 32);
      }
      
      &-secondary-logo {
        @include original-proportions-image(75, 75);

        @include breakpoint(small) {
          @include original-proportions-image(82, 82);
        }
      }
    }

    &_page-hero{
      @include fixed-proportion-image(375, 211);
      
      @include breakpoint(small) {
        @include fluid-image();
      }
    }
  }
}
