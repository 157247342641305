$max-icon-size: 16px;

.play-button {
	$self: &;
	position: relative;
	display: inline-flex;
	align-items: center;
	padding: 6px 12px;
	background-color: rgba(0, 30, 100, 0.7);
	@include font-size(16px, 24px);
	color: $white;
	font-family: $font-family;
	border: none;
	cursor: pointer;
	@include focus-outline('light', 3px);

	&:hover,
	&:focus-visible {
		background-color: $blue;
	}

	&__icon {
		margin-right: 8px;
		max-width: $max-icon-size;
		max-height: $max-icon-size;
	}
}