// Enable font smoothing
$enable-font-smoothing: true !default;


// Base font sizes and colors

$tera-size: 53px !default;
$giga-size: 28px !default;
$extra-large-size: 20px !default;
$large-size: 18px !default;
$medium-size: 16px !default;
$small-size: 14px !default;
$extra-small-size: 12px !default;



//------------------------------------
//  COLORS
//------------------------------------



$white: white;
$black: black;

$dark-blue: #001E64;
$blue: #0087CC;
$medium-blue: #85C9F0;
$light-blue: #EAF4FA;
$orange: #E87722;
$red: #C8102E;

$primary-color: $dark-blue;
$secondary-color: $blue;


$dark-orange: #EF563F;
$light-orange: #F9D7B5;
$green: #A2FF93;
$light-green: #D9FDCD;
$coral: #FF8977;
$light-coral: #F6C6BC;
$yellow: #FFEA79;
$light-yellow: #FDF4C1;

$beige: #F2E9D8;
$light-beige: #F7F4EC;
$dark-gray: #03123F80;
$gray: #7f7f7f;
$medium-gray: #959595;
$light-gray: #d0d0d0;

//-----------------------------------------------------
// Calculation variables
//-----------------------------------------------------
$base-font-size: 16px !default; // WARN! Never change - it is a constans needed for calculations.
$base-line-height: $extra-large-size !default;
$line-height-ratio: calc($base-line-height / $base-font-size);
//-------------------------------------------------------

$font-family: 'Radikal', Arial, sans-serif;
$font-family-secondary: 'Romain', Helvetica, sans-serif;
$font-size-em : #{calc($extra-large-size / $base-font-size) * 1em};
$font-weight-normal: normal;
$font-weight-medium: 500;
$font-weight-bold: bold;
$print-font-family: Georgia, "Times New Roman", Times, serif !default;
$mono-font-family: monospace, "Times New Roman", Times, serif !default;

$line-height: $giga-size;

$website-bg-color: $light-blue;

$validation-color: $red;

$media-overlay-color: $dark-blue;


//------------------------------------
// Spacing
//------------------------------------

$spacing-small: 5px;
$spacing-medium: 16px;
$spacing-xmedium: 20px;
$spacing-large: 40px;
$spacing-xlarge: 50px;
$spacing-xxlarge: 60px;
$spacing-xxxlarge: 100px;
$spacing-giga: 140px;
$spacing-narrow: 220px;
 
$max-width: 1440px;
$max-width-l: 1044px;
$max-width-m: 990px;

$max-content-width: 652px;
$min-width: 320px;
$text-box-width: 480px;

$header-height: 114px;
$header-height-tablet: 165px;
$header-height-mobile: 157px;

$block-margin: convert-to-rem(48);
$border-width: 2px;

//------------------------------------
//  RESPONSIVE
//------------------------------------

$ipad-pro-breakpoint: 1025px;

// Breakpoints
$breakpoint-map: (
  "extra-small": 30em, //480px
  "extra-small-max": 47.999em,
  "small":       48em, //ipad-min
  "small-max": 63.999em,
  "medium":      64em, //ipad-max
  "medium-max": 79.999em,
  "large":       80em, //1280px
  "extra-large": 100em,//1600 px
  "container-max": $max-width, //1440px centered container
  "ipad": (min-device-width 768px) (max-device-width 1024px),
  "ipad-pro": $ipad-pro-breakpoint,
  "iphone": (min-device-width 320px) (max-device-width 480px),
  "iphone5": (min-device-width 320px) (max-device-width 568px)
);

// Selection colors
$selection-background:  $blue;

// Form placeholder colors
$placeholder-color:     #bbbbbb !default;


// Colors used for our objects' borders, etc
$object-ui-color:       #329C42 !default;


// Dropdown colors
$dropdown-bg-color:     #fffa37 !default;
$dropdown-border-color: #ededed !default;
$dropdown-hover-color:  #f3f8f8 !default;
$dropdown-header-color: #aaaaaa !default;


//------------------------------------
//  Components
//------------------------------------

// Gutter size
$gutters: 2% !default;


// Prefix for grid columns. By default columns
// are formatted as `.column-8`
$column-prefix: 'column-' !default;


// Dictate how many columns you would like your
// grid system to use. Default is 12.
$column-number: 16 !default;

$use-grid:           true !default;
$use-push:	       true !default;
$use-pull:	       true !default;
